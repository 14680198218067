<template>
    <div class="padding-around">
        <div class="container">
            <div class="row">
                <h4 class="col-12 mb-3" v-if="form.lesson_type !== LESSON_TYPE_PERIODIC">(#{{ form.id }}) Занятие от {{ form.date_from | toDateTitle }}</h4>
                <h4 class="col-12 mb-3" v-else>Расписание ({{form.schedule.weekdaysFormatted}}) c {{formatScheduleBeginTime}} по {{formatScheduleEndTime}}</h4>
                <div class="alert alert-warning text-dark col-12 text-size week-days-changed-alert" role="alert" v-if="weekDaysChanged && form.lesson_type === LESSON_TYPE_PERIODIC">
                  Дни занятий изменены! Все регулярные занятия этого ученика, созданные позже {{currentBeginDate}} будут <b><u>пересозданы</u></b>!
                </div>
                <b-form class="row g-1" @submit.prevent="handleUpdate">
                    <b-form-group v-if="isSchedule" label="Тип занятия:" class="col-6" label-for="input-lesson_type_title">
                        <b-form-select
                                id="input-lesson_type_title"
                                disabled
                                :options="successLessonsTypeList"
                                :value="form.lesson_type"
                                v-model="form.lesson_type"
                        ></b-form-select>
                    </b-form-group>
                    <b-form-group v-else label="Тип занятия:" class="col-6" label-for="input-lesson_type_title">
                      <b-form-select
                          id="input-lesson_type_title"
                          class=""
                          :options="successLessonsTypeList"
                          :value="form.lesson_type"
                          v-model="form.lesson_type"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group v-if="!teacher" label="Преподаватель:" class="col-6" label-for="input-teacher_id">
                        <b-form-select
                                id="input-teacher_id"
                                class=""
                                :options="getTeachersOptionsList"
                                :value="form.teacher_id"
                                :state="!!form.teacher_id"
                                v-model="form.teacher_id"
                        ></b-form-select>
                        <b-form-invalid-feedback>Должен быть выбран преподаватель</b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group v-if="teacher" label="Преподаватель:" class="col-6" label-for="input-lesson_type_title">
                      <b-form-input v-if="form.teacher" v-model="form.teacher.full_name" type="text" disabled></b-form-input>
                    </b-form-group>

                  <b-form-group v-if="form.lesson_type === LESSON_TYPE_PERIODIC" label="Дни недели:" class="col-6" label-for="dummy-input">
                    <div class="weekdays-checkbox-wrap">
                      <b-form-checkbox v-model="wDays.wdMon" :value="1" :unchecked-value="0" :disabled="weekDays === 1">Пн.</b-form-checkbox>
                      <b-form-checkbox v-model="wDays.wdTue" :value="1" :unchecked-value="0" :disabled="weekDays === 2">Вт.</b-form-checkbox>
                      <b-form-checkbox v-model="wDays.wdWed" :value="1" :unchecked-value="0" :disabled="weekDays === 4">Ср.</b-form-checkbox>
                      <b-form-checkbox v-model="wDays.wdThu" :value="1" :unchecked-value="0" :disabled="weekDays === 8">Чт.</b-form-checkbox>
                      <b-form-checkbox v-model="wDays.wdFri" :value="1" :unchecked-value="0" :disabled="weekDays === 16">Пт.</b-form-checkbox>
                      <b-form-checkbox v-model="wDays.wdSat" :value="1" :unchecked-value="0" :disabled="weekDays === 32">Сб.</b-form-checkbox>
                      <b-form-checkbox v-model="wDays.wdSun" :value="1" :unchecked-value="0" :disabled="weekDays === 64">Вс.</b-form-checkbox>
                    </div>
                  </b-form-group>

                    <b-form-group v-if="form.lesson_type !== LESSON_TYPE_PERIODIC" label="Дата занятия:" class="col-6" label-for="input-date_from">
                        <b-form-datepicker id="input-date_from"
                                :start-weekday="1"
                                v-model="date_from"
                                :value="date_from"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                locale="ru"
                                :state="dateTimeValidator"
                                required>
                        </b-form-datepicker>
                        <b-form-invalid-feedback>Дата занятия не может быть пустой</b-form-invalid-feedback>
                    </b-form-group>

<!--                    <b-form-group id="input-group-1" label="Время занятия:" class="col-6" label-for="input-time_from">-->
<!--                        <NormalTimePicker v-model="time_from" id="original_lessontime" :state="timeValidation"/>-->
<!--                        <b-form-invalid-feedback>Время занятия не может быть пустым</b-form-invalid-feedback>-->
<!--                    </b-form-group>-->

                  <b-form-group id="input-group-1" label="Время занятия:" class="col-6" label-for="input-time_from">
                    <input type="time" class="input-time_from form-control" v-model="time_from">
                  </b-form-group>

                    <b-form-group v-if="form.lesson_type === LESSON_TYPE_PERIODIC" label="Период действия c:" class="col-6" label-for="input-beginDate_from">
                      <b-form-datepicker id="input-beginDate_from"
                                         disabled
                                         :start-weekday="1"
                                         :min="minEndDate"
                                         v-model="beginDate"
                                         :value="beginDate"
                                         :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                         locale="ru"
                                         :state="dateTimeValidator">
                      </b-form-datepicker>
                    </b-form-group>

                    <b-form-group v-if="form.lesson_type === LESSON_TYPE_PERIODIC" label="по:" class="col-6" label-for="input-endDate_from">
                      <b-form-datepicker id="input-endDate_from"
                                         :start-weekday="1"
                                         reset-button
                                         :min="minEndDate"
                                         v-model="endDate"
                                         :value="endDate"
                                         :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                         locale="ru"
                                         :state="dateTimeValidator">
                      </b-form-datepicker>
                    </b-form-group>

                    <b-form-group label="Продолжительность:" class="col-6" label-for="input-lesson_duration">
                        <b-form-select
                                id="input-lesson_duration"
                                class=""
                                :disabled="isLessonStart"
                                :options="getLessonDurations"
                                :state="!!lesson_duration"
                                :value="lesson_duration"
                                v-model="lesson_duration"
                        ></b-form-select>
                        <b-form-invalid-feedback>Продолжительность занятия не может быть пустой</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group v-if="form.lesson_type !== LESSON_TYPE_PERIODIC" label="Статус:" class="col-6" label-for="input-status">
                        <b-form-select
                                id="input-status"
                                class=""
                                :options="getAccessLessonStatusList"
                                :value="form.status"
                                v-model="form.status"
                        ></b-form-select>
                        <b-form-invalid-feedback>Статус занятия не может быть пустой</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group v-if="form.lesson_type !== LESSON_TYPE_PERIODIC" label="Заметка:" class="col-12" label-for="input-note">
                        <b-form-input id="input-note" v-model="form.note" type="text"></b-form-input>
                    </b-form-group>

                    <b-form-group
                        v-if="form.lesson_type !== LESSON_TYPE_PERIODIC"
                        label="Ссылка на комнату: (https://play.chessfirst.online/****)"
                        class="col-12"
                        label-for="input-room-url"
                    >
                        <b-form-input
                            id="input-room-url"
                            type="text"
                            :state="!(errors && errors.room_url)"
                            @input="handleRoomUrlInput"
                            v-model="form.room_url"
                        />
                        <b-form-invalid-feedback v-if="errors && errors.room_url">{{ errors.room_url }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group  label="Язык обучения:" :class="teacher && form.lesson_type === LESSON_TYPE_PERIODIC ? 'col-6': 'col-12'" label-for="input-lesson_type_title">
                        <b-form-select
                            id="input-lesson_type_title"
                            class=""
                            :disabled="isLessonStart"
                            :options="languageList"
                            :value="form.language"
                            v-model="form.language"
                        ></b-form-select>
                    </b-form-group>

                    <div class="col-12">
                        <hr/>
                    </div>

                    <b-form-group  label="ФИО ученика:" class="col-6" label-for="input-full_name">
                        <b-form-input id="input-full_name" v-model="form.student.full_name" type="text" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Логин игровой платформы:" class="col-6" label-for="input-age">
                        <b-form-input id="input-age" v-model="form.student.lichess_username" placeholder="---" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Возраст:" class="col-6" label-for="input-age">
                        <b-form-input id="input-age" v-model="form.student.age" placeholder="Возраст" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Опыт:" class="col-6" label-for="input-experience_title">
                        <b-form-input id="input-experience_title" v-model="experience_title" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Часовой пояс:" class="col-6" label-for="input-timezone">
                        <b-form-input id="input-timezone" v-model="form.student.timezone" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="ФИО родителя:" class="col-6" label-for="input-parent_name">
                        <b-form-input id="input-parent_name" v-model="form.student.parent_name" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Телефон родителя:" class="col-6" label-for="input-parent_phone">
                        <b-form-input id="input-parent_phone" v-model="form.student.parent_phone" disabled></b-form-input>
                    </b-form-group>

                    <div class="col-12" style="display: flex; justify-content: flex-end;">
<!--                        Пока этот функционал не нужен, закоментил -->
<!--                        <div style="display: flex;">-->
<!--                            <b-form-group class="text-left">-->
<!--                                <b-button v-if="!teacher && form.lesson_type !== LESSON_TYPE_PERIODIC" @click="removeLesson" class="mr-2 flex" variant="danger">-->
<!--                                    <b-spinner v-if="isDeleting" class="mr-2" style="width: 1.3rem; height: 1.3rem;" label="Sending"></b-spinner>-->
<!--                                    <span :class="{ captionPadding: isDeleting }">Удалить занятие</span>-->
<!--                                </b-button>-->
<!--                            </b-form-group>-->

<!--                            <b-form-group v-if="!teacher && form.lesson_type === LESSON_TYPE_PERIODIC" class="text-left">-->
<!--                                <b-button @click="removePeriodicLesson" class="mr-2 flex" variant="danger">-->
<!--                                    <b-spinner v-if="isDeleting" class="mr-2" style="width: 1.3rem; height: 1.3rem;" label="Sending"></b-spinner>-->
<!--                                    <span :class="{ captionPadding: isDeleting }">Удалить все расписание</span>-->
<!--                                </b-button>-->
<!--                            </b-form-group>-->
<!--                        </div>-->

                        <b-form-group class="text-right">
                            <b-button type="submit" class="mr-2" variant="success" :disabled="!canSend">
                                <b-spinner v-if="isSubmiting" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
                                <span :class="{ captionPadding: isSubmiting }">Сохранить</span>
                            </b-button>
                            <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
                        </b-form-group>
                    </div>

                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import momentTz from 'moment-timezone';
import {mapActions, mapGetters} from 'vuex';
// import DeleteModal from '@/components/Modal/DeleteModal';
import NormalTimePicker from '../../components/NormalTimePicker';
import {debounce} from "debounce";
import {validateRoomUrl} from "../../utils";
import dayjs from "dayjs";

export default {
        name: 'EditLessonModal',
        components: { NormalTimePicker },
        props: {
            teacher: {
              type: Boolean,
              default: false
            },
            isSchedule: {
              type: Boolean,
              default: false
            },
            lessonId: {
              type: Number,
              default: null,
              required: true
            },
            actionHandler: {
                type: Function,
                required: true
            },
            removeHandler: {
                type: Function,
                required: true
            }
        },
        data() {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const minEndDate = new Date(today);

            return {
                form: {
                    id: null,
                    date_from: null,
                    date_to: null,
                    status: null,
                    teacher_id: null,
                    teacher_full_name: null,
                    student: {
                        id: null,
                        full_name: null,
                        age: null,
                        timezone: null,
                        parent_name: null,
                        parent_phone: null,
                    },
                    schedule: {
                      weekdaysFormatted: null,
                    },
                    lesson_type: null,
                    note: null,
                    room_url: null,
                    language: 'ru',
                },
                date_from: null,
                time_from: null,
                lesson_duration: null,
                isSubmiting: false,
                languageList: [],
                isDeleting: false,
                errors: null,
                beginDate: null,
                endDate: null,
                minEndDate: minEndDate,
                wDays : {
                  wdMon: 0,
                  wdTue: 0,
                  wdWed: 1,
                  wdThu: 0,
                  wdFri: 1,
                  wdSat: 0,
                  wdSun: 1,
                },
                weekDays: 84, // понедельник, среда, пятница (0010101)
                weekDaysChanged: false,
                lessonsTypeList: [],
                LESSON_TYPE_PERIODIC: 1000,
                lessonsTypeSelectDisabled: false,
            };
        },
        filters: {
          toDateTitle(value) {
            return moment(value).format('DD MMM (dddd)');
          }
        },
        watch: {
          wDays: {
            handler(newValue, oldValue) {
                this.weekDays = parseInt(`${this.wDays.wdSun}${this.wDays.wdSat}${this.wDays.wdFri}${this.wDays.wdThu}${this.wDays.wdWed}${this.wDays.wdTue}${this.wDays.wdMon}`, 2);
              // console.log('weekDays: ' + this.weekDays);
              this.weekDaysChanged = this.weekDays !== this.form.schedule.weekdays
            },
            deep: true
          },
        },
        computed: {
          ...mapGetters({
            getExperienceList: 'common/getExperienceList',
            getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
            getTeachersOptionsList: 'teacher/getTeachersOptionsList',
            getLessonTypeList: 'common/getLessonTypeList',
            getLessonStatusList: 'common/getLessonStatusList',
            getLessonDurations: 'common/getLessonDurations',
            getTeacherList: 'teacher/getTeacherList',
          }),
          dateTimeValidator() {
            return !!this.date_from;
          },
          timeValidation() {
            return !!this.time_from;
          },
          canSend() {
            return this.dateTimeValidator
                && this.timeValidation
                && this.lesson_duration
                && this.form.teacher_id;
          },
          experience_title: function () {
            for (let item of this.getExperienceList) {
              if (this.form.student.experience === item.value)
                return item.text
            }
            return 'Не определенно'
          },
          currentBeginDate: function () {
            if (this.isSchedule) {
              return moment().format('DD.MM.YYYY')
            }
            return moment(this.form.date_from).format('DD.MM.YYYY')
          },
          formatScheduleBeginTime() {
            return moment(`${this.form.schedule.begin_date} ${this.form.schedule.begin_time}`).format('HH:mm')
          },
          formatScheduleEndTime() {
            return moment(`${this.form.schedule.begin_date} ${this.form.schedule.end_time}`).format('HH:mm')
          },
          getAccessLessonStatusList: function () {
            let statusList = this.getLessonStatusList
            // if (this.teacher) {
            //   for (let item of this.getLessonStatusList) {
            //     if (item.value === 1) item.disabled = true
            //   }
            // }
            return statusList
          },
          weekdaysFormatted: function () {
            let weekDays = [ 'воскресенье', 'суббота', 'пятница', 'четверг', 'среда', 'вторник', 'понедельник'];
            let weekDaysCurrent = `${this.wDays.wdSun}${this.wDays.wdSat}${this.wDays.wdFri}${this.wDays.wdThu}${this.wDays.wdWed}${this.wDays.wdTue}${this.wDays.wdMon}`.split('').map(string => parseInt(string))
            let result = []
            weekDaysCurrent.forEach((element, index) => {
              if (element) {
                result.push(weekDays[index])
              }
            })
            return result.toString()
          },
          successLessonsTypeList: function() {
            let lessonsTypeList = [...this.getLessonTypeList];
            if (this.form.schedule || this.isSchedule) lessonsTypeList.push({ text: 'Регулярное занятие', value: this.LESSON_TYPE_PERIODIC })
            if (this.teacher) {
              for (let item of lessonsTypeList) {
                if (item.value === 1) item.disabled = true
              }
            }
            return lessonsTypeList
          },
            isLessonCompleted() {
                return this.form.date_to && moment(this.form.date_to).isBefore(momentTz().tz("Europe/Moscow").format('YYYY-MM-DD HH:mm'))
            },
            isLessonStart() {
                return this.form.date_from && moment(this.form.date_from).isSameOrBefore(momentTz().tz("Europe/Moscow").format('YYYY-MM-DD HH:mm'));
            }
        },
        methods: {
          ...mapActions({
              fetchTeachersOptions: 'teacher/fetchTeachersOptions',
            setScheduleRefresh: 'scheduler/scheduleRefresh',
            setLessonsRefresh: 'scheduler/lessonsRefresh',
            updateLessonDetails: 'lessons/updateLessonDetails',
            updateTeacherLessonDetails: 'lessons/updateTeacherLessonDetails',
            // getRemoveLesson: 'lessons/removeLesson',
          }),
          moment: function () {
            return moment();
          },
          async fetchLessonDetail() {
            try {
              let response = await this.$axios.post(`/api/teacher/lesson/${this.lessonId}`, {
                lesson_id: this.lessonId
              });
              this.form = response.data
              this.date_from = this.form.date_from
            } catch (e) {
              let errMsg = `Ошибка загрузки занятия.`;
              if (e.response && e.response.data.error) {
                errMsg = e.response.data.error;
              }
              else if (e.response && e.response.data.message) {
                errMsg = e.response.data.message;
              }
              this.showErrorNotify(errMsg)
              throw e;
            }
          },
          async fetchScheduleDetail() {
            try {
              let response = await this.$axios.get(`/crm/schedules/${this.lessonId}/edit-info`);
              this.form.schedule = response.data.schedule
              this.form.student = response.data.student
              this.form.teacher = response.data.teacher
              this.form.teacher_id = response.data.teacher.id
            } catch (e) {
              let errMsg = `Ошибка загрузки занятия.`;
              if (e.response && e.response.data.error) {
                errMsg = e.response.data.error;
              }
              else if (e.response && e.response.data.message) {
                errMsg = e.response.data.message;
              }
              this.showErrorNotify(errMsg)
              throw e;
            }
          },
          async fetchLanguage() {
              try {
                const {data} = await this.$axios.get('crm/lesson/language_list');
                this.languageList = data;
              } catch (ex) {
                console.log('cant fetch data: ' + ex);
              }
          },

          async handleUpdate() {
              // невалидная ссылка на комнату
              if (this.errors && this.errors.room_url) {
                  const input = document.getElementById('input-room-url');
                  if (input) input.focus();
                  this.$toasted.error(this.errors.room_url, { position: 'bottom-right' });
                  return false;
              }

              this.isSubmiting = true;

              if (this.form.lesson_type === this.LESSON_TYPE_PERIODIC) {
                  await this.updatePeriodicLesson()
                  return true
              }
              await this.updateLesson()
          },
          async updatePeriodicLesson() {
            let dummyDate = new Date('1970-01-01T' + `${this.time_from}:00`);
            let beginTime = dummyDate.toLocaleTimeString('en-US', {hourCycle: "h23"});
            let endTime = (new Date(dummyDate.getTime() + Number(this.lesson_duration) * 60000)).toLocaleTimeString('en-US', {hourCycle: "h23"});

            try {
              await this.$axios.post(`/crm/schedules/${this.form.schedule.id}/update`, {
                schedule_id: this.form.schedule.id,
                student_id: this.form.student.id,
                teacher_id: this.form.teacher_id,
                lesson_id: this.form.id,
                begin_date: this.beginDate,
                end_date: this.endDate,
                weekdays: this.weekDays,
                begin_time: beginTime,
                end_time: endTime,
                language: this.form.language,
                duration: this.lesson_duration,
              });
            } catch (e) {
              let errMsg = `Ошибка обновления регулярного занятия.`;
              if (e.response && e.response.data.error) {
                errMsg = e.response.data.error;
              }
              else if (e.response && e.response.data.message) {
                errMsg = e.response.data.message;
              }
              this.isSubmiting = false;
              this.showErrorNotify(errMsg)
              throw e;
            }
              await this.actionHandler()
            await this.setLessonsRefresh()
            await this.setScheduleRefresh()
            this.showNotify('Регулярное занятие успешно обновлено.')
            this.isSubmiting = false;
            this.$modal.hideAll();
            this.$store.state.lessons.periodicLessonCreateSuccessFlag = true;
          },
          async updateLesson() {
            try {
              let data = {
                id: this.form.id,
                student_id: this.form.student_id,
                teacher_id: this.form.teacher_id,
                note: this.form.note,
                room_url: this.form.room_url,
                date_from: moment(this.date_from).format('YYYY-MM-DD') + ' ' + this.timeFormatter(this.time_from) + ':00',
                duration: this.lesson_duration,
                status: this.form.status,
                lesson_type: this.form.lesson_type,
                language: this.form.language,
                lesson_id: this.form.id
              }

              if (this.teacher){
                const result = await this.updateTeacherLessonDetails(data);
              } else {
                const result = await this.updateLessonDetails(data);
              }

            } catch (e) {
              let errMsg = `Произошла ошибка, урок не был сохранен!`;
              if (e.response && e.response.data.error) {
                errMsg = e.response.data.error;
              }
              else if (e.response && e.response.data.message) {
                errMsg = e.response.data.message;
              }
              this.showErrorNotify(errMsg)
              this.isSubmiting = false;
              throw e;
            }
            this.showNotify('Урок сохранен!');
            await this.setScheduleRefresh()
              await this.actionHandler()
            this.isSubmiting = false;
            this.$modal.hideAll();
          },

          // функционал удаления

          // async removePeriodicLesson() {
          //     this.$modal.show(DeleteModal, {
          //         recordId: this.form.schedule.id,
          //         recordName: 'Все будущие и прошлые занятия с ' + this.form.student.full_name,
          //         extraInfo: this.weekdaysFormatted,
          //         extraInfo2: `C ${this.form.schedule.begin_time.substr(0, 5)} по ${this.form.schedule.end_time.substr(0, 5)}`,
          //         deleteHandler: this.approvePeriodicDeletion
          //     }, { name: 'DeleteModal' });
          // },
          // async removeLesson() {
          //   this.$modal.show(DeleteModal, {
          //     recordId: this.form.id,
          //     recordName: 'Занятие с ' + this.form.student.full_name + ' от ' + moment(this.date_from).format('DD.MM.YYYY'),
          //     deleteHandler: this.approveDeletion
          //   }, { name: 'DeleteModal' });
          // },
          //
          // async approvePeriodicDeletion() {
          //     this.isDeleting = true;
          //     try {
          //         await this.$axios.post('/api/lessons/remove-periodic', {
          //             id: this.form.schedule.id,
          //         });
          //     } catch (e) {
          //         let errMsg = `Ошибка удаления регулярного занятия.`;
          //         if (e.response && e.response.data.error) {
          //             errMsg = e.response.data.error;
          //         }
          //         else if (e.response && e.response.data.message) {
          //           errMsg = e.response.data.message;
          //         }
          //         this.showErrorNotify(errMsg)
          //         this.isSubmiting = false;
          //         throw e;
          //     }
          //     this.showNotify('Регулярного занятия успешно удалено.')
          //     this.isDeleting = false;
          //     this.$store.state.lessons.periodicLessonRemoveSuccessFlag = true;
          //     this.$modal.hideAll();
          // },
          // async approveDeletion() {
          //     this.isDeleting = true;
          //     try {
          //       const status = await this.getRemoveLesson({
          //         id: this.form.id
          //       });
          //     } catch (e) {
          //       let errMsg = `Ошибка удаления занятия.`;
          //       if (e.response && e.response.data.error) {
          //         errMsg = e.response.data.error;
          //       }
          //       else if (e.response && e.response.data.message) {
          //         errMsg = e.response.data.message;
          //       }
          //       this.showErrorNotify(errMsg)
          //       throw e;
          //     }
          //   await this.setScheduleRefresh()
          //   this.isDeleting = false;
          //   this.$modal.hideAll();
          //   this.showNotify('Занятие успешно удалено')
          // },

          closeModal() {
            this.$modal.hideAll()
          },
          showNotify(text) {
            this.$toasted.success(text, {
              position: 'bottom-right',
              action: {
                text: 'Закрыть',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            });
          },
          showErrorNotify(text) {
            this.$toasted.error(text, {
              position: 'bottom-right',
              action: {
                text: 'Закрыть',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            });
          },

          formatDate(date) {
            if (!date) {
              return null;
            }
            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let yyyy = date.getFullYear();
            dd = dd < 10 ? '0' + dd : dd;
            mm = mm < 10 ? '0' + mm : mm;
            return `${yyyy}-${mm}-${dd}`;
          },
          timeFormatter(value) {
              if (value.split(':').length > 1) {
                  let hour = value.split(':')[0];
                  hour = hour.length < 2 ? `0${hour}` : hour;
                  let min = value.split(':')[1];
                  min = min.length < 2 ? `0${min}` : min;
                  return `${hour}:${min}`;
              }
              return '';
          },
          handleRoomUrlInput: debounce(function(val) {
              if (!val) return true;

              if (!validateRoomUrl(val)) {
                  const message = 'Введите правильную ссылку на комнату';

                  if (this.errors) {
                      this.$set(this.errors, 'room_url', message);
                  } else {
                      this.errors = {
                          room_url: message,
                      }
                  }
              } else {
                  if (this.errors && this.errors.room_url) this.$delete(this.errors, 'room_url');
              }
          }, 500),
          convertNumberToWeekBinary(number){
            number = number.toString(2)
            let array = (""+number).split("").map(Number)

            if (array.length !== 7) {
              for (let i = array.length; i < 7; i++) {
                array.unshift(0)
              }
            }
            this.wDays = {
                  wdMon: array[6],
                  wdTue: array[5],
                  wdWed: array[4],
                  wdThu: array[3],
                  wdFri: array[2],
                  wdSat: array[1],
                  wdSun: array[0],
            }
          },
        },
        async created() {

            if (!this.teacher && !this.getTeachersOptionsList.length) {
                await this.fetchTeachersOptions();
            }

              if (this.isSchedule)
              {
                this.form.lesson_type = this.LESSON_TYPE_PERIODIC
                await this.fetchScheduleDetail()
              } else {
                await this.fetchLessonDetail()

              }
              await this.fetchLanguage();
              if (this.form.schedule){
                this.weekDays = this.form.schedule.weekdays
                this.convertNumberToWeekBinary(this.weekDays)
                this.beginDate = this.form.schedule.begin_date
                this.endDate = this.form.schedule.end_date
              }

              this.date_from = moment(this.form.date_from).format('YYYY-MM-DD');

              if (this.isSchedule){
                this.time_from = dayjs(`${this.form.schedule.begin_date} ${this.form.schedule.begin_time}`).format('HH:mm')
                this.lesson_duration = moment(`${this.form.schedule.begin_date} ${this.form.schedule.end_time}`).diff( moment(`${this.form.schedule.begin_date} ${this.form.schedule.begin_time}`), 'minutes' );
              } else {
                this.time_from = moment(this.form.date_from).locale('ru').format('HH:mm');
                this.lesson_duration = moment(this.form.date_to).diff( moment(this.form.date_from), 'minutes' );
              }
        }
    }
</script>

<style scoped lang="scss">
.padding-around {
    padding: 14px 16px 0 14px;
}
.captionPadding {
    margin-left: 10px;
}

.week-days-changed-alert {
  font-size: 14px;
}

.weekdays-checkbox-wrap {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.weekdays-checkbox-wrap > div {
  margin-right: 1.5rem;
}
</style>
