<template>
    <div class="padding-around">
        <div class="container">
            <div class="row">
                <h4 class="col-12 mb-3">Новое занятие</h4>
                <b-form class="row g-1" @submit.prevent="saveLesson">
                    <b-form-group v-if="!isSchedule" label="Тип занятия:" class="col-6" label-for="input-lesson_type_title">
                        <b-form-select
                            id="input-lesson_type_title"
                            class=""
                            :options="lessonTypeList"
                            :value="lesson_type"
                            :disabled="isLesson && teacher"
                            v-model="lesson_type"
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group v-if="isSchedule" label="Тип занятия:" class="col-6" label-for="input-lesson_type_title">
                        <b-form-input id="input-lesson_type_title" type="text" value="Регулярное занятие" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group v-if="!teacher" label="Преподаватель:" class="col-6" label-for="input-teacher_id">
                        <b-form-select
                            id="input-teacher_id"
                            class=""
                            :options="getTeachersOptionsList"
                            :state="!!teacher_id"
                            v-model="teacher_id"
                            @change="selectedTeacher"
                        />
                        <b-form-invalid-feedback>Должен быть выбран преподаватель</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group v-if="teacher" label="Преподаватель:" class="col-6" label-for="input-teacher_id">
                        <b-form-input id="input-teacher_id" :value="teacherFullName" disabled type="text"></b-form-input>
                    </b-form-group>

                    <b-form-group v-if="lesson_type !== LESSON_TYPE_PERIODIC" label="Дата занятия:" class="col-6" label-for="input-date_from">
                        <b-form-datepicker id="input-date_from"
                                   :start-weekday="1"
                                   v-model="date_from"
                                   :value="date_from"
                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                   locale="ru"
                                   :state="dateTimeValidator"
                                   required>
                        </b-form-datepicker>
                        <b-form-invalid-feedback>Дата занятия не может быть пустым</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group v-if="lesson_type === LESSON_TYPE_PERIODIC" label="Дни недели:" class="col-6" label-for="dummy-input">
                        <div class="weekdays-checkbox-wrap">
                            <b-form-checkbox v-model="wDays.wdMon" :value="1" :unchecked-value="0" :disabled="weekDays === 1">Пн.</b-form-checkbox>
                            <b-form-checkbox v-model="wDays.wdTue" :value="1" :unchecked-value="0" :disabled="weekDays === 2">Вт.</b-form-checkbox>
                            <b-form-checkbox v-model="wDays.wdWed" :value="1" :unchecked-value="0" :disabled="weekDays === 4">Ср.</b-form-checkbox>
                            <b-form-checkbox v-model="wDays.wdThu" :value="1" :unchecked-value="0" :disabled="weekDays === 8">Чт.</b-form-checkbox>
                            <b-form-checkbox v-model="wDays.wdFri" :value="1" :unchecked-value="0" :disabled="weekDays === 16">Пт.</b-form-checkbox>
                            <b-form-checkbox v-model="wDays.wdSat" :value="1" :unchecked-value="0" :disabled="weekDays === 32">Сб.</b-form-checkbox>
                            <b-form-checkbox v-model="wDays.wdSun" :value="1" :unchecked-value="0" :disabled="weekDays === 64">Вс.</b-form-checkbox>
                        </div>
                    </b-form-group>

                    <b-form-group id="input-group-1" label="Время занятия:" class="col-6" label-for="input-time_from">
                      <input type="time" class="input-time_from form-control" v-model="time_from">
                    </b-form-group>

                    <b-form-group v-if="lesson_type === LESSON_TYPE_PERIODIC" label="Период действия c:" class="col-6" label-for="input-beginDate_from">
                        <b-form-datepicker id="input-beginDate_from"
                                           :start-weekday="1"
                                           :min="minEndDate"
                                           v-model="beginDate"
                                           :value="beginDate"
                                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                           locale="ru"
                                           :state="dateTimeValidator">
                        </b-form-datepicker>
                    </b-form-group>

                    <b-form-group v-if="lesson_type === LESSON_TYPE_PERIODIC" label="по:" class="col-6" label-for="input-endDate_from">
                        <b-form-datepicker id="input-endDate_from"
                                           :start-weekday="1"
                                           reset-button
                                           :min="minEndDate"
                                           v-model="endDate"
                                           :value="endDate"
                                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                           locale="ru"
                                           :state="dateTimeValidator">
                        </b-form-datepicker>
                    </b-form-group>

                    <b-form-group label="Продолжительность:" class="col-6" label-for="input-lesson_duration">
                        <b-form-select
                                id="input-lesson_duration"
                                class=""
                                :value="lesson_duration"
                                v-model="lesson_duration"
                                @change="changeLanguageOrDuration"
                        >
                          <b-form-select-option
                              v-for="lessonDuration in lessonDurations"
                              :key="lessonDuration"
                              :value="lessonDuration"
                              :disabled="!availableDurations.includes(parseInt(lessonDuration))"
                          >
                            {{lessonDuration}}
                          </b-form-select-option>
                        </b-form-select>
                        <b-form-invalid-feedback>Продолжительность занятия не может быть пустой</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group v-if="lesson_type !== LESSON_TYPE_PERIODIC" label="Заметка:" class="col-6" label-for="input-note">
                        <b-form-input id="input-note" v-model="note" type="text"></b-form-input>
                    </b-form-group>

                    <b-form-group v-if="lesson_type !== LESSON_TYPE_PERIODIC"
                        label="Ссылка на комнату: (https://play.chessfirst.online/****)"
                        class="col-12"
                        label-for="input-room-url"
                    >
                        <b-form-input
                            id="input-room-url"
                            type="text"
                            :state="!(errors && errors.room_url)"
                            @input="handleRoomUrlInput"
                            v-model="room_url"
                        />
                        <b-form-invalid-feedback v-if="errors && errors.room_url">{{ errors.room_url }}</b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group  label="Язык обучения:" :class="lesson_type !== LESSON_TYPE_PERIODIC ? 'col-12': 'col-6'" label-for="input-lesson_type_title">
                        <b-form-select
                            id="input-lesson_type_title"
                            class=""
                            :options="languageList"
                            :value="language"
                            v-model="language"
                            @change="changeLanguageOrDuration"
                        ></b-form-select>
                    </b-form-group>

<!--                    <b-form-group v-if="studentIdPrepared" label="Ученик:" class="col-12" label-for="input-student">
                        <b-form-input id="input-student" v-model="student.full_name" type="text" disabled></b-form-input>
                    </b-form-group>-->

                    <b-form-group label="Ученик:" v-if="teacher && !studentIdPrepared" class="col-12" label-for="input-student">
                      <VueMultiselect
                          class='custom__multiselect'
                          :multiple='false'
                          :close-on-select='true'
                          :clear-on-select='true'
                          :preserve-search='true'
                          selectedLabel='Выбран'
                          selectLabel='Добавить'
                          deselectLabel='Убрать'
                          placeholder='Выберите студента'
                          :tagable='false'
                          :searchable="true"
                          tagPosition='bottom'
                          label='full_name'
                          track-by='id'
                          :options='students'
                          :value='student'
                          @input="handleSelectStudent"
                          @search-change='lookupStudents'
                          @remove="prepareAvailableDurations"
                      >
                        <template #noOptions>Нет данных</template>
                        <template #noResult>Ничего не найдено</template>
                      </VueMultiselect>
                    </b-form-group>

                    <b-form-group v-if="!teacher && !studentIdPrepared" label="Ученик:" class="col-12" label-for="input-student">
                      <vue-typeahead-bootstrap
                          id="std-search"
                          class="mb-4"
                          v-model="searchWord"
                          :ieCloseFix="false"
                          :screen-reader-text-serializer="student => student.full_name"
                          :serializer="student => student.full_name"
                          :data="students"
                          placeholder="Поиск ученика"
                          @input="lookupStudents()"
                          @hit="student = $event"
                      />
                    </b-form-group>

                    <div class="col-12">
                        <hr/>
                    </div>

                    <b-form-group  label="ФИО ученика:" class="col-6" label-for="input-full_name">
                        <b-form-input id="input-full_name" v-model="student.full_name" type="text" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Возраст:" class="col-6" label-for="input-age">
                        <b-form-input id="input-age" v-model="student.age" placeholder="Возраст" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Опыт:" class="col-6" label-for="input-experience_title">
                        <b-form-input id="input-experience_title" v-model="student.experience_title" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Часовой пояс:" class="col-6" label-for="input-timezone">
                        <b-form-input id="input-timezone" v-model="student.timezone" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="ФИО родителя:" class="col-6" label-for="input-parent_name">
                        <b-form-input id="input-parent_name" v-model="student.parent_name" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group label="Телефон родителя:" class="col-6" label-for="input-parent_phone">
                        <b-form-input id="input-parent_phone" v-model="student.parent_phone" disabled></b-form-input>
                    </b-form-group>

                    <b-form-group class="col-12 text-right">
                        <b-button type="submit" class="mr-2" variant="success" :disabled="!canSend || !student.id">
                            <b-spinner v-if="isSubmitting" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
                            <span :class="{ captionPadding: isSubmitting }">Сохранить</span>
                        </b-button>
                        <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
                    </b-form-group>

                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import {mapActions, mapGetters, mapMutations} from 'vuex';
    import { debounce } from "debounce";
    import { validateRoomUrl } from "../../utils";
    import VueMultiselect from 'vue-multiselect';

    export default {
        name: 'CreateLessonModal',
        components: {VueMultiselect},
        props: {
            teacher: {
              type: Boolean,
              default: false,
            },
            isSchedule: {
              type: Boolean,
              default: false,
            },
            isLesson: {
              type: Boolean,
              default: false,
            },
            lessonDatePrepared: {
              type: String,
              default: null
            },
            lessonTimePrepared: {
              type: String,
              default: null
            },
            studentIdPrepared: {
                type: Number,
                default: null
            },
            beforeCreatedAction: {
                type: Function,
                required: true
            }
        },
        data() {
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const minEndDate = new Date(today);

            this.LESSON_TYPE_PERIODIC = 1000;
            this.NOT_SELECTED_DURATION = 'Не установлено';

            return {
                query: '', // autocomplete fn
                students: [],
                availableDurations: [],
                searchWord: null,
                student: {
                    id: null,
                    full_name: null,
                    age: null,
                    experience_title: null,
                    timezone: null,
                    parent_name: null,
                    parent_phone: null,
                },
                // data fields
                date_from: null,
                lesson_type: 0,
                language: 'ru',
                teacher_id: null,
                note: '',
                time_from: '09:00',
                lesson_duration: '25',
                room_url: null,
                currentTeacher: null,
                isSubmitting: false,
                lessonTypeList: [],
                languageList: [],
                beginDate: null,
                endDate: null,
                minEndDate: minEndDate,
                wDays : {
                    wdMon: 0,
                    wdTue: 0,
                    wdWed: 1,
                    wdThu: 0,
                    wdFri: 1,
                    wdSat: 0,
                    wdSun: 1,
                },
                weekDays: 84, // понедельник, среда, пятница (0101010)
                lessonDurations: [],
                timeOptions: [
                    '00:00', '00:30','01:00','01:30','02:00','02:30','03:00', '03:30', '04:00', '04:30', '05:00', '05:30',
                    '06:00', '06:30', '07:00', '07:30', '08:00','08:30', '09:00', '09:30', '10:00', '10:30', '11:00',
                    '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30',
                    '17:00', '17:30', '18:00', '18:30', '19:00',  '19:30', '20:00', '20:30', '21:00', '21:30', '22:00',
                    '22:30', '23:00', '23:30',
                ],
                errors: null,
            };
        },
        filters: {
            toDateTitle(value) {
                return moment(value).format('DD MMM (dddd)');
            }
        },
        methods: {
            ...mapMutations({
              resetPosition: 'scheduler/resetPosition',
            }),
            ...mapActions({
                fetchTeachersOptions: 'teacher/fetchTeachersOptions',
              setScheduleRefresh: 'scheduler/scheduleRefresh',
              createTeacherLesson: 'lessons/createTeacherLesson',
            }),
            formatDate(date) {
                if (!date) {
                    return null;
                }
                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear();
                dd = dd < 10 ? '0' + dd : dd;
                mm = mm < 10 ? '0' + mm : mm;
                return `${yyyy}-${mm}-${dd}`;
            },
            async fetchLanguage() {
                try {
                  const {data} = await this.$axios.get('crm/lesson/language_list');
                  this.languageList = data;
                } catch (ex) {
                  console.log('cant fetch data: ' + ex);
                }
            },

            async saveLesson() {
                // невалидная ссылка на комнату
                this.isSubmitting = true;

                if (!this.validateBeforeSave()) {
                  this.isSubmitting = false;
                  return false;
                }

                if (this.errors && this.errors.room_url) {
                    const input = document.getElementById('input-room-url');
                    if (input) input.focus();
                    this.$toasted.error(this.errors.room_url, { position: 'bottom-right' });
                    return false;
                }

                if (this.lesson_type === this.LESSON_TYPE_PERIODIC) {
                    let dummyDate = new Date('1970-01-01T' + `${this.time_from}:00`);
                    let beginTime = dummyDate.toLocaleTimeString('en-US', {hourCycle: "h23"});
                    let endTime = (new Date(dummyDate.getTime() + Number(this.lesson_duration) * 60000)).toLocaleTimeString('en-US', {hourCycle: "h23"});

                    try {
                        await this.$axios.post('/crm/schedules/create', {
                            student_id: this.student.id,
                            teacher_id: this.teacher_id,
                            begin_date: this.beginDate,
                            end_date: this.endDate,
                            weekdays: this.weekDays,
                            begin_time: beginTime,
                            duration: this.lesson_duration,
                            language: this.language,
                        });
                        this.resetPosition()
                    }
                    catch (e) {
                        let errMsg = `Ошибка создания регулярного занятия.`;
                        if (e.response && e.response.data.error) {
                            errMsg = e.response.data.error;
                        }
                        else if (e.response && e.response.data.message) {
                          errMsg = e.response.data.message;
                        }
                        this.showErrorNotify(errMsg)
                      this.isSubmitting = false;
                      throw e;
                    }
                    this.showNotify('Регулярное занятие успешно создано.')
                    this.$store.state.lessons.periodicLessonCreateSuccessFlag = true;
                }
                else {
                    try {
                        this.isSubmitting = true;
                        const status = await this.createTeacherLesson({
                            student_id: this.student.id,
                            teacher_id: this.teacher_id,
                            note: this.note,
                            date_from: moment(this.date_from).format('YYYY-MM-DD') + ' ' + this.timeFormatter(this.time_from) + ':00',
                            duration: this.lesson_duration,
                            lesson_type: this.lesson_type,
                            room_url: this.room_url,
                            language: this.language,
                        });
                    }
                    catch (e) {
                        let errMsg = `Ошибка создания занятия.`;
                        if (e.response && e.response.data.error) {
                            errMsg = e.response.data.error;
                        }
                        else if (e.response && e.response.data.message) {
                            errMsg = e.response.data.message;
                        }
                        this.showErrorNotify(errMsg)
                        this.isSubmitting = false;
                        throw e;
                    }
                    this.showNotify('Занятие успешно создано.')
                    this.resetPosition()
                }
                this.beforeCreatedAction()
                this.isSubmitting = false;
                this.$emit('close')
            },
            changeLanguageOrDuration() {
              if (this.teacher && this.student && Object.keys(this.student).length && this.student.subscriptions) {
                this.availableTimes()
              }
              this.lookupStudents()
            },
            async lookupStudents() {
                try {
                  if (this.teacher) {
                    const response = await this.$axios.get(`/crm/teachers/${this.userId}/students`, {
                      params: {
                        search: this.searchWord,
                        // duration: this.lesson_duration,
                        // language: this.language,
                      }
                    })
                    this.students = response.data.data;
                  } else {
                    if (!this.searchWord || this.searchWord.length < 2) {
                      return [];
                    }
                    const response = await this.$axios.get('/api/students', {
                      params: {
                        search: this.searchWord
                      }
                    });
                    this.students = response.data.items;
                  }

                } catch (e) {
                    console.error(e);
                }
            },
            async fetchPreparedStudent() {
                const response = await this.$axios.get(`/api/students/${this.studentIdPrepared}`);
                this.student = response.data;
            },
            handleSelectStudent(val) {
              if (val && Object.keys(val).length) {
                this.student = val;
              } else {
                this.student = {
                  full_name: null,
                  age: null,
                  experience_title: null,
                  timezone: null,
                  parent_name: null,
                  parent_phone: null,
                }
              }
              if (this.teacher && this.student && Object.keys(this.student).length && this.student.subscriptions) {
                this.availableTimes()
              }
            },
            closeModal() {
              this.$modal.hideAll();
            },
            timeFormatter(value) {
                if (value.split(':').length > 1) {
                    let hour = value.split(':')[0];
                    hour = hour.length < 2 ? `0${hour}` : hour;
                    let min = value.split(':')[1];
                    min = min.length < 2 ? `0${min}` : min;
                    return `${hour}:${min}`;
                }
                return '';
            },
            handleRoomUrlInput: debounce(function(val) {
                if (!val) return true;

                if (!validateRoomUrl(val)) {
                    const message = 'Введите правильную ссылку на комнату';

                    if (this.errors) {
                        this.$set(this.errors, 'room_url', message);
                    } else {
                        this.errors = {
                            room_url: message,
                        }
                    }
                } else {
                    if (this.errors && this.errors.room_url) this.$delete(this.errors, 'room_url');
                }
            }, 500),
            setLessonTypeList() {
              this.lessonTypeList = [...this.getLessonTypeList];
              if(!this.isLesson) {
                  this.lessonTypeList.push({ text: 'Регулярное занятие', value: this.LESSON_TYPE_PERIODIC });
              }
              if (this.teacher) {
                this.lessonTypeList.forEach((element, index) => {
                  if (element.value === 1) {
                    this.lessonTypeList.splice(index, 1)
                  }
                })
              }
                if (this.isSchedule) {
                    this.lesson_type = this.LESSON_TYPE_PERIODIC
                }
            },
            selectedTeacher(teacherId) {
                const teacher = this.getTeachersOptionsList.find(t => t.value == teacherId);

                if (teacher) {
                    this.language = teacher.language === 2 ? "en" : "ru";
                }
            },
            showNotify(text) {
                this.$toasted.success(text, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            },
            showErrorNotify(text) {
                this.$toasted.error(text, {
                    position: 'bottom-right',
                    action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            },
            availableTimes() {
              let times = []

              if (this.student.subscriptions) {
                this.student.subscriptions.forEach((v, i) => {
                  let language = Object.keys(this.languageList).find(key => this.languageList[key] === v.product.language)
                  if (language === this.language) {
                    if (!times.includes(v.product.duration)) {
                      times.push(v.product.duration)
                    }
                  }
                })
              }

              if (!times.length) {
                this.lesson_duration = this.NOT_SELECTED_DURATION
              }

              if (times.length && !times.includes(parseInt(this.lesson_duration))) {
                this.lesson_duration = times[0]
              }

              this.availableDurations = times
            },
            prepareAvailableDurations() {
              this.availableDurations = [this.NOT_SELECTED_DURATION, ...this.getLessonDurations.map(v => parseInt(v))]
            },
            validateBeforeSave() {
              if (this.lesson_duration === this.NOT_SELECTED_DURATION) {
                this.showErrorNotify('Необходимо выбрать длительность занятия')
                return false
              }

              return true
            }
        },
        computed: {
            ...mapGetters({
                getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
                getTeachersOptionsList: 'teacher/getTeachersOptionsList',
                getLessonTypeList: 'common/getLessonTypeList',
                getLessonStatusList: 'common/getLessonStatusList',
                getLessonDurations: 'common/getLessonDurations',
                userId: 'user/getUserId',
                userName: 'user/getName',
                getTeacherList: 'teacher/getTeacherList'
            }),
            dateTimeValidator() {
                return !!this.date_from;
            },
            timeValidation() {
                return !!this.time_from;
            },
            canSend() {
                return this.dateTimeValidator
                    && this.timeValidation
                    && this.lesson_duration
                    && this.teacher_id;
            },
            teacherFullName() {
                return this.$store.state.user.teacher_profile.full_name ?? null
            },
        },
        watch: {
            wDays: {
                handler(newValue, oldValue) {
                    this.weekDays = parseInt(`${this.wDays.wdSun}${this.wDays.wdSat}${this.wDays.wdFri}${this.wDays.wdThu}${this.wDays.wdWed}${this.wDays.wdTue}${this.wDays.wdMon}`, 2);
                    //console.log('weekDays: ' + this.weekDays);
                },
                deep: true
            },
            time_from(newValue, oldValue) {
                return this.lessonDurations;
            },
            lesson_duration(newValue, oldValue) {
               return this.timeOptions = [...this.timeOptionsFull];
            },
        },
        async created() {
            this.lessonDurations = ['Не установлено', ...this.getLessonDurations];
            this.date_from = moment().format('YYYY-MM-DD');

            if (!this.teacher && !this.getTeachersOptionsList.length) {
                await this.fetchTeachersOptions();
            }

            if (this.lessonDatePrepared) {
                let date = this.lessonDatePrepared
                date = new Date(date.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1'))
                this.date_from = moment(date).format('YYYY-MM-DD')
            }

            if (this.lessonTimePrepared) {
                this.time_from = this.lessonTimePrepared
            }
            await this.fetchLanguage();

            if (this.studentIdPrepared) {
                await this.fetchPreparedStudent()
            }

            this.beginDate = this.formatDate(new Date());
            this.timeOptionsStripped = [...this.timeOptions];
            this.timeOptionsStripped.splice(-2);
            this.timeOptionsFull = [...this.timeOptions];
            this.setLessonTypeList()

            this.prepareAvailableDurations()
            if (this.teacher) {
              this.teacher_id = this.userId
              this.selectedTeacher(this.userId);
              this.beginDate = moment(this.date_from) > moment() ? this.date_from : moment().format('YYYY-MM-DD')
              await this.lookupStudents()
              if (this.studentIdPrepared) {
                this.student = this.students.find(({id}) => id === this.studentIdPrepared)
                this.availableTimes()
              }
            }
        }
    }
</script>

<style scoped lang="scss">
.padding-around {
    padding: 14px 16px 0 14px;
}
.captionPadding {
    margin-left: 10px;
}

.weekdays-checkbox-wrap {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
}
.weekdays-checkbox-wrap > div {
    margin-right: 1.5rem;
}

option:disabled {
  background: #cbcbcb;
}

</style>

<style>
#std-search .vbt-autcomplete-list {
    max-height: 280px !important;
}
</style>

