var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"lessons-legend mt-2 mb-4"},[(_vm.showFreeTime)?_c('li',{staticClass:"lessons-legend-item"},[_c('div',{staticClass:"lessons-legend-color --free-time"}),_c('div',{staticClass:"lessons-legend-title"},[_vm._v("Свободное время")])]):_vm._e(),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lessons-legend-item"},[_c('div',{staticClass:"lessons-legend-color --regular-lesson"}),_c('div',{staticClass:"lessons-legend-title"},[_vm._v("Обычный урок")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lessons-legend-item"},[_c('div',{staticClass:"lessons-legend-color --trial-lesson"}),_c('div',{staticClass:"lessons-legend-title"},[_vm._v("Пробный урок")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lessons-legend-item"},[_c('div',{staticClass:"lessons-legend-color --planned"}),_c('div',{staticClass:"lessons-legend-title"},[_vm._v("Запланирован")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lessons-legend-item"},[_c('div',{staticClass:"lessons-legend-color --finished"}),_c('div',{staticClass:"lessons-legend-title"},[_vm._v("Завершён")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lessons-legend-item"},[_c('div',{staticClass:"lessons-legend-color --skipped"}),_c('div',{staticClass:"lessons-legend-title"},[_vm._v("Пропущен")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lessons-legend-item"},[_c('div',{staticClass:"lessons-legend-color --canceled"}),_c('div',{staticClass:"lessons-legend-title"},[_vm._v("Отменен")])])
}]

export { render, staticRenderFns }