import { serialize } from './object-to-form-data';

export function validateRoomUrl(url, validStart = 'https://play.chessfirst.online/') {
    if (!url) return true; // пропускаем пустую ссылку

    // https://play.chessfirst.online/XWjiK824 - валидная ссылка
    const startLength = validStart.length;
    const start = url.slice(0, startLength);

    return start === validStart && url.length > startLength;
}

export function prepareForm(formData, options = {}) {
    const form = new FormData();
    const defaultOptions = {
        /**
         * добавляет индексы массивам
         * defaults to false
         */
        indices: true,

        /**
         * treat null values like undefined values and ignore them
         * defaults to false
         */
        nullsAsUndefineds: true,

        /**
         * convert true or false to 1 or 0 respectively
         * defaults to false
         */
        booleansAsIntegers: true,

        /**
         * store arrays even if they're empty
         * defaults to false
         */
        allowEmptyArrays: false,
    };
    const mergedOptions = Object.assign({}, defaultOptions, options);

    return serialize(formData, mergedOptions, form);
}
