<template>
    <ul class="lessons-legend mt-2 mb-4">
        <li class="lessons-legend-item" v-if="showFreeTime">
            <div class="lessons-legend-color --free-time"></div>
            <div class="lessons-legend-title">Свободное время</div>
        </li>
        <li class="lessons-legend-item">
            <div class="lessons-legend-color --regular-lesson"></div>
            <div class="lessons-legend-title">Обычный урок</div>
        </li>
        <li class="lessons-legend-item">
            <div class="lessons-legend-color --trial-lesson"></div>
            <div class="lessons-legend-title">Пробный урок</div>
        </li>
        <li class="lessons-legend-item">
            <div class="lessons-legend-color --planned"></div>
            <div class="lessons-legend-title">Запланирован</div>
        </li>
        <li class="lessons-legend-item">
            <div class="lessons-legend-color --finished"></div>
            <div class="lessons-legend-title">Завершён</div>
        </li>
        <li class="lessons-legend-item">
            <div class="lessons-legend-color --skipped"></div>
            <div class="lessons-legend-title">Пропущен</div>
        </li>
        <li class="lessons-legend-item">
            <div class="lessons-legend-color --canceled"></div>
            <div class="lessons-legend-title">Отменен</div>
        </li>
    </ul>
</template>

<script>
export default {
    name: "LessonLegendComponent",
    props: {
        showFreeTime: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">
.lessons-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 15px;
    margin: 0 !important;
    .lessons-legend-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 13px;
        .lessons-legend-color {
            width: 15px;
            border-radius: 100%;
            height: 15px;

            &.--free-time {
                background-color: $free_time;
                border-radius: 4px;
            }

            &.--trial-lesson {
                background-color: $trial_lesson;
                height: 5px;
                border-radius: 0;
            }

            &.--regular-lesson {
                background-color: $regular_lesson;
                height: 5px;
                border-radius: 0;
            }

            &.--planned {
                background-color: $planned_lesson;
            }
            &.--finished {
                background-color: $finish_lesson;
            }
            &.--skipped {
                background-color: $skipped_lesson;
            }
            &.--canceled {
                background-color: $canceled_lesson;
            }
        }

        .lessons-legend-title {

        }
    }
}
</style>